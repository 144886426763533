import _core2 from "@styled-system/core";
import _space2 from "@styled-system/space";
import _color2 from "@styled-system/color";
import _layout2 from "@styled-system/layout";
import _typography2 from "@styled-system/typography";
import _flexbox2 from "@styled-system/flexbox";
import _border2 from "@styled-system/border";
import _background2 from "@styled-system/background";
import _position2 from "@styled-system/position";
import _grid2 from "@styled-system/grid";
import _shadow2 from "@styled-system/shadow";
import _variant2 from "@styled-system/variant";
var exports = {};
exports.__esModule = true;
exports.style = exports.colorStyle = exports.textStyle = exports.buttonStyle = exports.variant = exports.left = exports.bottom = exports.right = exports.top = exports.zIndex = exports.backgroundRepeat = exports.backgroundPosition = exports.backgroundSize = exports.backgroundImage = exports.borders = exports.borderRadius = exports.borderLeft = exports.borderBottom = exports.borderRight = exports.borderTop = exports.borderColor = exports.borderStyle = exports.borderWidth = exports.gridArea = exports.gridTemplateAreas = exports.gridTemplateRows = exports.gridTemplateColumns = exports.gridAutoRows = exports.gridAutoColumns = exports.gridAutoFlow = exports.gridRow = exports.gridColumn = exports.gridRowGap = exports.gridColumnGap = exports.gridGap = exports.order = exports.alignSelf = exports.justifySelf = exports.flexBasis = exports.flexShrink = exports.flexGrow = exports.flex = exports.flexDirection = exports.flexWrap = exports.justifyContent = exports.justifyItems = exports.alignContent = exports.alignItems = exports.letterSpacing = exports.fontStyle = exports.textAlign = exports.lineHeight = exports.fontWeight = exports.fontFamily = exports.fontSize = exports.opacity = exports.overflow = exports.display = exports.verticalAlign = exports.size = exports.maxHeight = exports.maxWidth = exports.minHeight = exports.minWidth = exports.height = exports.width = exports.textShadow = exports.boxShadow = exports.shadow = exports.grid = exports.position = exports.background = exports.border = exports.flexbox = exports.typography = exports.layout = exports.color = exports.space = exports.padding = exports.margin = exports.system = exports.compose = exports.get = void 0;
var _core = _core2;
exports.createStyleFunction = _core.createStyleFunction;
exports.createParser = _core.createParser;
exports.get = _core.get;
exports.compose = _core.compose;
exports.system = _core.system;
var _space = _space2;
exports.margin = _space.margin;
exports.padding = _space.padding;
exports.space = _space.space;

var _color = _interopRequireWildcard(_color2);

exports.color = _color.color;
exports.opacity = _color["default"];

var _layout = _interopRequireWildcard(_layout2);

exports.layout = _layout.layout;
exports.width = _layout["default"];
exports.height = _layout["default"];
exports.minWidth = _layout["default"];
exports.minHeight = _layout["default"];
exports.maxWidth = _layout["default"];
exports.maxHeight = _layout["default"];
exports.size = _layout["default"];
exports.verticalAlign = _layout["default"];
exports.display = _layout["default"];
exports.overflow = _layout["default"];

var _typography = _interopRequireWildcard(_typography2);

exports.typography = _typography.typography;
exports.fontSize = _typography["default"];
exports.fontFamily = _typography["default"];
exports.fontWeight = _typography["default"];
exports.lineHeight = _typography["default"];
exports.textAlign = _typography["default"];
exports.fontStyle = _typography["default"];
exports.letterSpacing = _typography["default"];

var _flexbox = _interopRequireWildcard(_flexbox2);

exports.flexbox = _flexbox.flexbox;
exports.alignItems = _flexbox["default"];
exports.alignContent = _flexbox["default"];
exports.justifyItems = _flexbox["default"];
exports.justifyContent = _flexbox["default"];
exports.flexWrap = _flexbox["default"];
exports.flexDirection = _flexbox["default"];
exports.flex = _flexbox["default"];
exports.flexGrow = _flexbox["default"];
exports.flexShrink = _flexbox["default"];
exports.flexBasis = _flexbox["default"];
exports.justifySelf = _flexbox["default"];
exports.alignSelf = _flexbox["default"];
exports.order = _flexbox["default"];

var _border = _interopRequireWildcard(_border2);

exports.border = _border.border;
exports.borderWidth = _border["default"];
exports.borderStyle = _border["default"];
exports.borderColor = _border["default"];
exports.borderTop = _border["default"];
exports.borderRight = _border["default"];
exports.borderBottom = _border["default"];
exports.borderLeft = _border["default"];
exports.borderRadius = _border["default"];
exports.borders = _border["default"];

var _background = _interopRequireWildcard(_background2);

exports.background = _background.background;
exports.backgroundImage = _background["default"];
exports.backgroundSize = _background["default"];
exports.backgroundPosition = _background["default"];
exports.backgroundRepeat = _background["default"];

var _position = _interopRequireWildcard(_position2);

exports.position = _position.position;
exports.zIndex = _position["default"];
exports.top = _position["default"];
exports.right = _position["default"];
exports.bottom = _position["default"];
exports.left = _position["default"];

var _grid = _interopRequireWildcard(_grid2);

exports.grid = _grid.grid;
exports.gridGap = _grid["default"];
exports.gridColumnGap = _grid["default"];
exports.gridRowGap = _grid["default"];
exports.gridColumn = _grid["default"];
exports.gridRow = _grid["default"];
exports.gridAutoFlow = _grid["default"];
exports.gridAutoColumns = _grid["default"];
exports.gridAutoRows = _grid["default"];
exports.gridTemplateColumns = _grid["default"];
exports.gridTemplateRows = _grid["default"];
exports.gridTemplateAreas = _grid["default"];
exports.gridArea = _grid["default"];

var _shadow = _interopRequireWildcard(_shadow2);

exports.shadow = _shadow.shadow;
exports.boxShadow = _shadow["default"];
exports.textShadow = _shadow["default"];
var _variant = _variant2;
exports.variant = _variant.variant;
exports.buttonStyle = _variant.buttonStyle;
exports.textStyle = _variant.textStyle;
exports.colorStyle = _variant.colorStyle;

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
} // v4 api shims
// v4 style API shim


var style = function style(_ref) {
  var prop = _ref.prop,
      cssProperty = _ref.cssProperty,
      alias = _ref.alias,
      key = _ref.key,
      transformValue = _ref.transformValue,
      scale = _ref.scale,
      properties = _ref.properties;
  var config = {};
  config[prop] = (0, _core.createStyleFunction)({
    properties: properties,
    property: cssProperty || prop,
    scale: key,
    defaultScale: scale,
    transform: transformValue
  });
  if (alias) config[alias] = config[prop];
  var parse = (0, _core.createParser)(config);
  return parse;
};

exports.style = style;
export default exports;
export const __esModule = exports.__esModule,
      colorStyle = exports.colorStyle,
      textStyle = exports.textStyle,
      buttonStyle = exports.buttonStyle,
      variant = exports.variant,
      left = exports.left,
      bottom = exports.bottom,
      right = exports.right,
      top = exports.top,
      zIndex = exports.zIndex,
      backgroundRepeat = exports.backgroundRepeat,
      backgroundPosition = exports.backgroundPosition,
      backgroundSize = exports.backgroundSize,
      backgroundImage = exports.backgroundImage,
      borders = exports.borders,
      borderRadius = exports.borderRadius,
      borderLeft = exports.borderLeft,
      borderBottom = exports.borderBottom,
      borderRight = exports.borderRight,
      borderTop = exports.borderTop,
      borderColor = exports.borderColor,
      borderStyle = exports.borderStyle,
      borderWidth = exports.borderWidth,
      gridArea = exports.gridArea,
      gridTemplateAreas = exports.gridTemplateAreas,
      gridTemplateRows = exports.gridTemplateRows,
      gridTemplateColumns = exports.gridTemplateColumns,
      gridAutoRows = exports.gridAutoRows,
      gridAutoColumns = exports.gridAutoColumns,
      gridAutoFlow = exports.gridAutoFlow,
      gridRow = exports.gridRow,
      gridColumn = exports.gridColumn,
      gridRowGap = exports.gridRowGap,
      gridColumnGap = exports.gridColumnGap,
      gridGap = exports.gridGap,
      order = exports.order,
      alignSelf = exports.alignSelf,
      justifySelf = exports.justifySelf,
      flexBasis = exports.flexBasis,
      flexShrink = exports.flexShrink,
      flexGrow = exports.flexGrow,
      flex = exports.flex,
      flexDirection = exports.flexDirection,
      flexWrap = exports.flexWrap,
      justifyContent = exports.justifyContent,
      justifyItems = exports.justifyItems,
      alignContent = exports.alignContent,
      alignItems = exports.alignItems,
      letterSpacing = exports.letterSpacing,
      fontStyle = exports.fontStyle,
      textAlign = exports.textAlign,
      lineHeight = exports.lineHeight,
      fontWeight = exports.fontWeight,
      fontFamily = exports.fontFamily,
      fontSize = exports.fontSize,
      opacity = exports.opacity,
      overflow = exports.overflow,
      display = exports.display,
      verticalAlign = exports.verticalAlign,
      size = exports.size,
      maxHeight = exports.maxHeight,
      maxWidth = exports.maxWidth,
      minHeight = exports.minHeight,
      minWidth = exports.minWidth,
      height = exports.height,
      width = exports.width,
      textShadow = exports.textShadow,
      boxShadow = exports.boxShadow,
      shadow = exports.shadow,
      grid = exports.grid,
      position = exports.position,
      background = exports.background,
      border = exports.border,
      flexbox = exports.flexbox,
      typography = exports.typography,
      layout = exports.layout,
      color = exports.color,
      space = exports.space,
      padding = exports.padding,
      margin = exports.margin,
      system = exports.system,
      compose = exports.compose,
      get = exports.get,
      createStyleFunction = exports.createStyleFunction,
      createParser = exports.createParser;
const _style = exports.style;
export { _style as style };